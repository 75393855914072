function PrivacyProtection() {
  return (
    <div className="p-4 w-4/5 mx-auto">
      <h1>Datenschutzerklärung</h1>
      <br />
      <h2>1. Datenschutz auf einen Blick</h2>
      <br />
      <h2>Allgemeine Hinweise</h2>
      <br />
      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
        besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
        persönlich identifiziert werden können. Ausführliche Informationen zum
        Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung. Datenerfassung auf unserer Website Wer ist
        verantwortlich für die Datenerfassung auf dieser Website? Die
        Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.
        Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
        Wie erfassen wir Ihre Daten? Ihre Daten werden zum einen dadurch
        erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um
        Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten
        werden automatisch beim Besuch der Website durch unsere IT-Systeme
        erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser,
        Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser
        Daten erfolgt automatisch, sobald Sie unsere Website betreten. Wofür
        nutzen wir Ihre Daten? Ein Teil der Daten wird erhoben, um eine
        fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten
        können zur Analyse Ihres Nutzerverhaltens verwendet werden. Welche
        Rechte haben Sie bezüglich Ihrer Daten? Sie haben jederzeit das Recht
        unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
        gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem
        ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu
        verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können
        Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns
        wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
        Aufsichtsbehörde zu. Analyse-Tools und Tools von Drittanbietern Beim
        Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet
        werden. Das geschieht vor allem mit Cookies und mit sogenannten
        Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der
        Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt
        werden. Sie können dieser Analyse widersprechen oder sie durch die
        Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen
        dazu finden Sie in der folgenden Datenschutzerklärung. Sie können dieser
        Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie
        in dieser Datenschutzerklärung informieren.
      </p>
      <br />
      <h2>2. Allgemeine Hinweise und Pflichtinformationen Datenschutz</h2>
      <br />
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung. Wenn Sie diese Website benutzen, werden
        verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind
        Daten, mit denen Sie persönlich identifiziert werden können. Die
        vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und
        wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
        geschieht. Wir weisen darauf hin, dass die Datenübertragung im Internet
        (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
        kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
        nicht möglich.
      </p>
      <br />
      <h2>Hinweis zur verantwortlichen Stelle</h2>
      <br />

      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist: Heike Kasper Kasperlino GmbH Grafenberger Allee 277-287 40237
        Düsseldorf Telefon:0211-436912303 E-Mail: heike.kasper@kadelo.de
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o.
        Ä.) entscheidet.
      </p>
      <br />
      <b>Widerruf Ihrer Einwilligung zur Datenverarbeitung</b>
      <br />
      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
        uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
        bleibt vom Widerruf unberührt.
      </p>
      <br />
      <b>Beschwerderecht bei der zuständigen Aufsichtsbehörde</b>
      <br />
      <p>
        Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
        Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
        Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen
        seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren
        Kontaktdaten können folgendem Link entnommen werden:
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
      </p>
      <br />
      <b>Recht auf Datenübertragbarkeit</b>
      <br />
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
      </p>
      <br />
      <b>SSL- bzw. TLS-Verschlüsselung</b>
      <br />
      <p>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
        dass die Adresszeile des Browsers von “http://” auf “https://” wechselt
        und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw.
        TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
        übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <br />
      <b>Verschlüsselter Zahlungsverkehr auf dieser Website</b>
      <br />
      <p>
        Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine
        Verpflichtung, uns Ihre Zahlungsdaten (z.B. Kontonummer bei
        Einzugsermächtigung) zu übermitteln, werden diese Daten zur
        Zahlungsabwicklung benötigt. Der Zahlungsverkehr über die gängigen
        Zahlungsmittel (Visa/MasterCard, Lastschriftverfahren) erfolgt
        ausschließlich über eine verschlüsselte SSL- bzw. TLS-Verbindung. Eine
        verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
        Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol
        in Ihrer Browserzeile. Bei verschlüsselter Kommunikation können Ihre
        Zahlungsdaten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
        werden.
      </p>
      <br />
      <b>Auskunft, Sperrung, Löschung</b>
      <br />
      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
        Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
        personenbezogene Daten können Sie sich jederzeit unter der im Impressum
        angegebenen Adresse an uns wenden.
      </p>
      <br />
      <b>Widerspruch gegen Werbe-Mails</b>
      <br />
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
        Werbung und Informationsmaterialien wird hiermit widersprochen. Die
        Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-E-Mails, vor.
      </p>
      <br />
      <h2>3. Datenerfassung auf unserer Website</h2>
      <br />
      <b>Cookies</b>
      <br />
      <p>
        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
        richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
        Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
        sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
        Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der
        von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie
        werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies
        bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese
        Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
        wiederzuerkennen. Sie können Ihren Browser so einstellen, dass Sie über
        das Setzen von Cookies informiert werden und Cookies nur im Einzelfall
        erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
        ausschließen sowie das automatische Löschen der Cookies beim Schließen
        des Browser aktivieren. Bei der Deaktivierung von Cookies kann die
        Funktionalität dieser Website eingeschränkt sein. Cookies, die zur
        Durchführung des elektronischen Kommunikationsvorgangs oder zur
        Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B.
        Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein
        berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit
        andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens)
        gespeichert werden, werden diese in dieser Datenschutzerklärung
        gesondert behandelt.
      </p>
      <br />
      <b>Server-Log-Dateien</b>
      <br />
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
        übermittelt. Dies sind: Browsertyp und Browserversion verwendetes
        Betriebssystem Referrer URL Hostname des zugreifenden Rechners Uhrzeit
        der Serveranfrage IP-Adresse Eine Zusammenführung dieser Daten mit
        anderen Datenquellen wird nicht vorgenommen. Grundlage für die
        Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung
        von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
        gestattet.
      </p>
    </div>
  );
}

export default PrivacyProtection;
